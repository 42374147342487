<template>
  <div id="salesPage">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'index'
}
</script>

<style lang="scss" scoped>
#salesPage {
  height: 100%;
}
</style>
